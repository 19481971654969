import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/logo.png'
import ClickableButton from '../components/ClickButton'
import right1 from '../assets/right.png'
import left from '../assets/leftTop.png'
import styles from '../styles'
import { isIOS, isAndroid, isBrowser } from 'react-device-detect'
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const navigate = useNavigate()

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleClick = () => {
    // window.open('https://t.me/catoffgaming', '_blank', 'noopener,noreferrer')
    navigate('/telegram')
  }
  const handleDownload = () => {
    if (isAndroid || isBrowser) {
      const url = 'https://indusapp.store/g8w5jjau' // Replace with your external link
      window.open(url, '_blank')
    } else {
      const url = 'https://game.catoff.xyz/' // Replace with your external link
      window.open(url, '_blank')
    }
  }
  return (
    <>
      <img
        src={left}
        className="absolute top-0 left-0 hidden md:block"
        style={{ width: '150px' }}
        alt="Left Image"
      />

      <div
        className={`relative flex justify-between items-center w-[90%]  ml-[3%]`}
      >
        <img
          src={logo}
          alt="Catoff Logo"
          className="w-36 relative -left-[30px] hidden md:block "
          onClick={() => navigate('/')}
        />
        <div
          className={`hidden md:flex justify-between items-center gap-10 px-10  ${styles.marginX} p-3 mr-[70px]`}
        >
          <div className="logo w-[100px]  pl-0"></div>
          <div className="menu flex gap-12">
            <button
              onClick={() => navigate('/catpaper')}
              className="text-black ml-2 font-sans text-lg transition-colors hover:text-gray-800"
            >
              Catpaper
            </button>
            <button
              onClick={() => navigate('/airdrop')}
              className="text-black font-sans text-lg transition-colors hover:text-gray-800"
            >
              Airdrop
            </button>
            <button
              onClick={handleClick}
              className="text-black font-sans text-lg transition-colors hover:text-gray-800 whitespace-nowrap"
            >
              Telegram
            </button>
            <button
              onClick={() => navigate('/blinks')}
              className="text-black text-lg transition-colors hover:text-gray-800"
            >
              Blinks
            </button>
            <button
              onClick={() => navigate('/quest')}
              className="text-black text-lg transition-colors hover:text-gray-800"
            >
              Quest
            </button>
            <button
              onClick={() => handleDownload()}
              className="text-black text-lg transition-colors hover:text-gray-800"
            >
              App
            </button>
          </div>
        </div>
        <div className="md:hidden flex flex-1 items-center bg-white justify-between px-5">
          <div className="logo">
            <img
              src={logo}
              alt="Catoff Logo"
              className="w-36 mr-5"
              onClick={() => navigate('/')}
            />
          </div>
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6 text-black"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              )}
            </svg>
          </button>
        </div>
        {menuOpen && (
          <div
            className={`md:hidden absolute left-0 right-0 bg-white shadow-lg transition-transform duration-300 ease-in-out ${styles.marginX} z-50`}
            style={{
              top: '100%',
            }}
          >
            <div className="p-5">
              <nav className="flex flex-col gap-4">
                <button
                  onClick={() => navigate('/catpaper')}
                  className="text-black text-lg transition-colors hover:text-gray-800"
                >
                  Catpaper
                </button>
                <button
                  onClick={() => navigate('/airdrop')}
                  className="text-black text-lg transition-colors hover:text-gray-800"
                >
                  Airdrop
                </button>
                <button
                  onClick={handleClick}
                  className="text-black text-lg transition-colors hover:text-gray-800"
                >
                  Telegram
                </button>
                <button
                  onClick={() => navigate('/blinks')}
                  className="text-black text-lg transition-colors hover:text-gray-800"
                >
                  Blinks
                </button>
                <button
                  onClick={() => navigate('/quest')}
                  className="text-black text-lg transition-colors hover:text-gray-800"
                >
                  Quest
                </button>
                <button
                  onClick={() => handleDownload()}
                  className="text-black text-lg transition-colors hover:text-gray-800"
                >
                  App
                </button>
              </nav>
            </div>
          </div>
        )}
        {windowWidth > 1100 && (
          <div className="whitespace-nowrap">
            <ClickableButton
              text="Join Challenge"
              bgColor="#DEFF5A"
              iconRight={right1}
              onClick={() => navigate('/challenge')}
              link="https://game.catoff.xyz/"
              isExternal={true}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Header
